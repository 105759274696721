import React from "react";
import { ThemeProvider } from "styled-components";

import Peracto from "@peracto/peracto";
import Config from "@peracto/peracto-config";
import PeractoUser from "@peracto/peracto-user";
import PeractoCatalogue from "@peracto/peracto-catalogue";
import PeractoCommon from "@peracto/peracto-common";
import PeractoContent from "@peracto/peracto-content";
import PeractoLocations from "@peracto/peracto-locations";
import PeractoPromotions from "@peracto/peracto-promotions";

import { AdminTheme, EditorTheme, globalStyles } from "./theme";
import blocks from "./blocks";

const config = new Config({
    api: process.env.REACT_APP_API,
    algolia: {
        applicationId: process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
        searchApiKey: process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY,
        indexName: process.env.REACT_APP_ALGOLIA_INDEX_NAME,
    },
    modules: [
        PeractoUser(),
        PeractoCommon(),
        PeractoCatalogue(),
        PeractoLocations(),
        PeractoPromotions(),
        PeractoContent(),
    ],
    editorConfig: {
        theme: EditorTheme,
        colorOptions: [
            { label: "Blue", value: EditorTheme.colors.brand.blue },
            { label: "Red", value: EditorTheme.colors.brand.red },
            { label: "Light Red", value: EditorTheme.colors.brand.redLight },
            { label: "Pale Red", value: EditorTheme.colors.brand.paleRed },
            { label: "Green", value: EditorTheme.colors.brand.green },
            { label: "Green Alt", value: EditorTheme.colors.brand.greenAlt },
            { label: "Pale Green", value: EditorTheme.colors.brand.paleGreen },
            { label: "Cyan", value: EditorTheme.colors.brand.cyan },
            { label: "Orange", value: EditorTheme.colors.brand.orange },
            { label: "Citrus", value: EditorTheme.colors.brand.citrus },
            { label: "Purple", value: EditorTheme.colors.brand.purple },
            { label: "Light Grey", value: EditorTheme.colors.brand.lightGrey },
            { label: "Grey Alt", value: EditorTheme.colors.brand.greyAlt },
            { label: "Dark Grey", value: EditorTheme.colors.brand.darkGrey },
        ],
        locales: ["en_GB", "es_ES", "fr_FR"],
        blocks,
        inputTypes: {},
        stylesheets: [
            "https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&family=Roboto:wght@300;400;500;700&display=swap",
        ],
        globalStyles,
    },
    features: {
        shippingServices: {
            deliveryDays: true,
        },
        products: {
            productVariants: true,
            exportProductData: true,
        },
        categories: {
            exportCategoryData: true,
        },
        user: {
            companySettings: true,
        },
    },
});

const App = () => (
    <ThemeProvider theme={AdminTheme}>
        <Peracto config={config} />
    </ThemeProvider>
);

export default App;
