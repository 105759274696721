import { theme } from "@chakra-ui/core";
import { css } from "@emotion/core";

const sizes = {
    sm: ["520px", "576px"],
    md: ["720px", "768px"],
    lg: ["960px", "992px"],
    xl: ["1170px", "1200px"],
};

const breakpoints = Object.values(sizes).reduce((bp, size) => {
    bp.push(size[1]);
    return bp;
}, []);

breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];

export const EditorTheme = {
    ...theme,
    breakpoints,
    colors: {
        ...theme.colors,
        brand: {
            blue: "#140F64",
            red: "#E11414",
            redLight: "#db5858",
            paleRed: "#FDF1F2",
            lightGrey: "#F2F2F2",
            grey: "#D8D8D8",
            greyAlt: "#d1d3d4",
            darkGrey: "#505050",
            black: "#222222",
            white: "#FFFFFF",
            green: "#2DB200",
            greenAlt: "#41ad49",
            paleGreen: "#E9F7E5",
            cyan: "#00aeef",
            orange: "#f78d1e",
            purple: "#4f2960",
            citrus: "#a7b539",
        },
    },
    fonts: {
        ...theme.fonts,
        heading: "'Roboto Condensed', sans-serif",
        condensed: "'Roboto Condensed', sans-serif",
        body: "Roboto, sans-serif",
    },
};

export const AdminTheme = {
    breakpoints: {
        mobileS: 320,
        mobileM: 375,
        mobileL: 425,
        tablet: 768,
        laptop: 992,
        desktop: 1024,
        desktopL: 1700,
    },
};

// Global styles designs: https://xd.adobe.com/view/8719609c-e869-4374-536d-6c33cb3e0878-f128/screen/d41afe39-82bf-4fdc-9f19-fb834a16dff3

const inputs = css`
    input::placeholder {
        color: ${EditorTheme.colors.brand.grey} !important;
    }

    div[type="radio"] {
        background-color: ${EditorTheme.colors.brand.white};
    }
`;

const unorderedList = css`
    ul {
        list-style: none;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 20px;
        position: relative;
        li {
            line-height: 30px;
            padding-left: 20px;
            position: relative;
            &:before {
                display: block;
                content: "";
                position: absolute;
                left: 0;
                top: 10px;
                height: 10px;
                width: 10px;
                margin-right: 10px;
                background-color: ${EditorTheme.colors.brand.red};
            }
        }
    }
`;

const carousel = css`
    ul.indicators {
        margin-bottom: 0;
        li {
            padding-left: 0;
            &:before {
                content: none;
            }
        }
    }

    .carousel .slide,
    .DayPicker {
        text-align: left;
        background: transparent;

        ul {
            margin-bottom: 0;
            li {
                padding-left: 0;
                &:before {
                    content: none;
                }
            }
        }
    }

    .carousel.carousel-slider {
        overflow: visible;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .control-dots {
        display: flex;
        justify-content: center;
    }
`;

const headings = css`
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: ${EditorTheme.fonts.heading};
    }

    h1 {
        font-size: 30px;
        line-height: 36px;
        margin-bottom: 10px;

        @media (max-width: 478px) {
            font-size: 24px;
            line-height: 30px;
        }
    }

    h2 {
        font-size: 28px;
        line-height: 34px;
        margin-bottom: 15px;

        @media (max-width: 478px) {
            font-size: 22px;
            line-height: 28px;
        }
    }

    h3 {
        font-size: 24px;
        line-height: 30px;

        @media (max-width: 478px) {
            font-size: 20px;
            line-height: 26px;
        }
    }

    h4 {
        font-size: 18px;
        line-height: 24px;
    }

    h5 {
        font-size: 16px;
        line-height: 22px;
    }

    h6 {
        font-size: 14px;
        line-height: 20px;
    }
`;

export const globalStyles = css`
    ${headings}
    ${inputs}
    ${unorderedList}
    ${carousel}
`;
